<template>
  <div>
    <static-fullscreen-card v-show="!idItemShow">
      <template v-slot:header></template>
      <template v-slot:actions>
        <a-btn-refresh @click="$refs.table.updateData()" />
        <a-btn-add :icon="'far fa-plus-square'" :title="'Создать'" v-if="getAccess('rent.create')" @click="createNew()" />
      </template>
      <portal to="v-main">
        <edit-dialog v-if="initEditDialog > 0 || true" v-model="showEditDialog" :id="idEdit" :api="url"></edit-dialog>
      </portal>
      <a-table-f-api
        ref="table"
        :api="url"
        :model="getModelList(m)"
        :useQuery="false"
        :defaults="defaults"
        :selectedRows="selectedRows"
        @click="
          counter += 1;
          onDetectClick($event);
        "
      />
    </static-fullscreen-card>
    <ViewItem v-if="idItemShow" :idShow="idItemShow" @close="itemShowClose($event)" />
  </div>
</template>

<script>
import { getAccess, keyDetect, genModel } from "@/components/mixings";
let time = null;
export default {
  mixins: [getAccess, keyDetect, genModel],

  components: {
    editDialog: () => import("./dialogs/rentDialog"),
    ViewItem: () => import("./views/rentView"),
  },
  data() {
    return {
      initEditDialog: 0,
      idEdit: 0,
      idItemShow: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.rent,
      url: "/mechti/rent",
      counter: 0,
      clickRow: null,
      click: undefined,
      currentRow: null,
      selectedRows: [],
      defaults: {
        sort: { key: "id", order: "DESC" },
        paramName: "rent",
      },
      infoSnack1: false,
      infoData: null,
      infoModel: null,
    };
  },
  created() {
    this.$root.title = "Аренда";
  },
  watch: {
    initEditDialog(v) {
      if (v == 1) {
        this.enableDialog();
      } else {
        this.showEditDialog = true;
      }
    },
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  computed: {},
  methods: {
    enableDialog() {
      this.$nextTick(() => {
        //this.showEditDialog = true;
        this.initEditDialog++;
      });
    },
    itemShowClose() {
      this.loading = true;
      this.$refs.table.updateRowData(this.idItemShow);
      this.loading = !true;
      this.idItemShow = 0;
    },
    test(e) {
      //e.data.shift();
    },

    onSingleClick(d) {
      if (this.$root.typeClick > 1) {
        this.clickRow = d.row.id;

        if (this.selectedRows.includes(d.row.id)) {
          this.selectedRows = [];
        } else {
          this.selectedRows = [d.row.id];
        }
      }
      if (this.$root.typeClick == 1) {
        this.onDblClick(d);
      }
      this.clickRow = 0;
    },
    onDblClick(d) {
      if (this.clickRow === d.row.id || this.$root.typeClick == 1) {
        this.idItemShow = d.row.id;
        return;
        this.$router.push({ name: "rent_view", params: { id: d.row.id } });
      }
      this.clickRow = 0;
    },
    onDetectClick(d) {
      if (this.counter == 1)
        this.click = setTimeout(() => {
          this.onSingleClick(d);
          this.click = undefined;
          this.counter = 0;
        }, 250);
      if (this.counter == 2) {
        this.counter = 0;
        clearTimeout(this.click);
        this.click = undefined;
        this.onDblClick(d);
      }
      this.clickRow = d.row.id;
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.initEditDialog++;
    },
  },
};
</script>
